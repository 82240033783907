<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Relatórios </q-tooltip>
      </q-btn>
    </q-toolbar>
    <!-- <q-intersection transition="slide-down" v-show="filter"> -->
    <q-form @submit.prevent="onFilterReports">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <q-toolbar-title> {{ $t('filter') }} </q-toolbar-title>
          <q-btn flat round icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <date-input
              buttons
              outlined
              label="Data dos cliques"
              :v-model="params.date"
              @update="(value) => (params.date = value)"
              clearable
              range
              :rules="[(val) => !!val || 'O campo data dos cliques é obrigatório']"
            />
          </div>
          <div class="col-6">
            <div class="row q-col-gutter-sm">
              <div class="col">
                <q-select
                  v-model="params.displayMode"
                  :options="optionsDisplay"
                  label="Modo de exibição"
                  :rules="[(val) => !!val || 'O campo de modo de exibição é obrigatório.']"
                  outlined
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <select-request
              :v-model="params.advertiser"
              outlined
              :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${getProfiles.affiliate_id}`"
              option-label="name"
              option-value="id"
              label="Canal"
              clearable
              @update="(value) => (params.advertiser = value === null ? null : value.id)"
            />
          </div>
          <div class="col-6">
            <select-request
              :v-model="params.campaign"
              outlined
              label="Campanha"
              :endpoint="`/redirect?app=ADVERTISER&path=/api/v2/get/campaign/list/affiliate/${getProfiles.affiliate_id}`"
              option-label="campaign_name"
              option-value="campaign_id"
              @update="(value) => (params.campaign = value === null ? null : value.campaign_id)"
            />
          </div>

          <div class="col-12 text-right">
            <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
          </div>
        </q-card-section>
      </q-card>
    </q-form>
    <!-- </q-intersection> -->
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';
import DateInput from '../../../widgets/inputsGlobal/DateInput';
import SelectRequest from '../../../widgets/inputsGlobal/SelectRequest';

export default {
  name: 'AffiliateHeaderClickReports',
  mixins: [ReportMixin],
  components: { DateInput, SelectRequest },

  data() {
    return {
      filter: true,
      params: {
        advertiser: null,
        campaign: null,
        displayMode: null,
        date: null
      },
      search: null,
      optionsDisplay: [
        {
          label: 'Por campanha',
          value: 'campaign'
        },
        {
          label: 'Por canal',
          value: 'site'
        },
        {
          label: 'Por dia',
          value: 'day'
        },
        {
          label: 'Por mês',
          value: 'month'
        },
        {
          label: 'Por ano',
          value: 'year'
        }
      ]
    };
  },
  watch: {
    search() {
      this.ActionSetFilterReports(this.search);
    },
    'params.campaign'() {
      console.log('===>', this.params.campaign);
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  },
  methods: {
    clearFilter() {
      this.params = {
        advertiser: null,
        campaign: null,
        displayMode: null,
        date: null
      };
      this.search = null;
    },

    onFilterReports() {
      let filterSelect = [];
      filterSelect.push(`id_affiliate=${this.getProfiles.affiliate_id}`);
      if (this.params.advertiser) filterSelect.push(`id_site=${this.params.advertiser}`);
      if (this.params.campaign) filterSelect.push(`id_campaign=${this.params.campaign}`);
      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }
      //   if (Array.isArray(this.params.date)) {
      //     if (this.params.date.split(" ").length === 3) {
      //       let from = this.params.date.split(" ")[0];
      //       let to = this.params.date.split(" ")[2];
      //       this.params.date = {
      //         from: this.$moment(
      //           this.stringToDate(from, "dd/MM/yyyy", "/")
      //         ).format("YYYY-MM-DD"),
      //         to: this.$moment(this.stringToDate(to, "dd/MM/yyyy", "/")).format(
      //           "YYYY-MM-DD"
      //         ),
      //       };

      //       filterSelect.push(`start_date=${this.params.date.from}`);
      //       filterSelect.push(`end_date=${this.params.date.to}`);
      //     }
      //   } else {
      //     this.params.date = this.$moment(
      //       this.stringToDate(this.params.date, "dd/MM/yyyy", "/")
      //     ).format("YYYY-MM-DD");
      //     filterSelect.push(`start_date=${this.params.date}`);
      //     filterSelect.push(`end_date=${this.params.date}`);
      //   }
      if (this.params.displayMode) filterSelect.push(`filter=${this.params.displayMode.value}`);

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getClickReportAffiliate();
    }
  }
};
</script>
