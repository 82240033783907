<template>
  <q-intersection transition="slide-down" v-show="this.show">
    <q-table
      flat
      bordered
      :data="reports"
      row-key="id"
      :columns="columns"
      :pagination="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportClickReport()"
          unelevated
        >
          <q-tooltip> Exportar como CSV</q-tooltip>
        </q-btn>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          display-value="Personalizar Colunas"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>

      <template v-slot:body-cell-ctr="props">
        <q-td :props="props">
          {{ `${props.row.ctr.toFixed(2)}%` }}
        </q-td>
      </template>

      <template v-slot:header-cell-valor_total_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor total de conversões geradas no site do anunciante que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-valor_total_aprovado="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor total de conversões geradas no site do anunciante que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-cpc_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPC que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-cpc_aprovado="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPC que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:header-cell-comissao_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPA/CPL que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:header-cell-comissao_aprovada="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPA/CPL que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:bottom-row v-if="reports.length > 1">
        <q-tr class="text-bold">
          <q-td> TOTAL: </q-td>
          <q-td> {{ reportsTotal.impressoes }} </q-td>
          <q-td> {{ reportsTotal.total }} </q-td>
          <q-td> {{ reportsTotal.visitantes_unicos }} </q-td>
          <!-- <q-td> {{ reportsTotal.ctr.toFixed(2) }}% </q-td> -->
          <q-td> - </q-td>
          <q-td> {{ reportsTotal.cadastros_pendentes }} </q-td>
          <q-td> {{ reportsTotal.cadastros_aprovados }} </q-td>
          <!-- <q-td> {{ reportsTotal.lcr.toFixed(2) }}% </q-td> -->
          <q-td> - </q-td>
          <q-td> {{ reportsTotal.vendas_pendentes }} </q-td>
          <q-td> {{ reportsTotal.vendas_aprovadas }} </q-td>
          <!-- <q-td> {{ reportsTotal.scr.toFixed(2) }}% </q-td> -->
          <q-td> - </q-td>
          <q-td>
            {{ parseMoney(reportsTotal.valor_total_pendente) }}
          </q-td>
          <q-td>
            {{ parseMoney(reportsTotal.valor_total_aprovado) }}
          </q-td>
          <q-td> {{ parseMoney(reportsTotal.cpc_pendente) }} </q-td>
          <q-td> {{ parseMoney(reportsTotal.cpc_aprovado) }} </q-td>
          <q-td>
            {{ parseMoney(reportsTotal.comissao_pendente) }}
          </q-td>
          <q-td>
            {{ parseMoney(reportsTotal.comissao_aprovada) }}
          </q-td>
        </q-tr>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          Linhas por página: &nbsp;
          <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 25, 50]"
            options-dense
            menu-shrink
            dense
            borderless
          />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </q-intersection>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';

export default {
  name: 'ListClickReports',
  mixins: [ReportMixin],

  data() {
    return {
      visibleColumns: null,
      columns: [
        {
          name: 'indice',
          label: 'Índice',
          align: 'left',
          field: 'indice',
          sortable: false
        },
        {
          name: 'impressoes',
          label: 'Impressões',
          align: 'left',
          field: 'impressoes',
          sortable: false
        },
        {
          name: 'total',
          label: 'Cliques totais',
          align: 'left',
          field: 'total',
          sortable: false
        },
        {
          name: 'visitantes_unicos',
          label: 'Cliques únicos',
          align: 'left',
          field: 'visitantes_unicos',
          sortable: false
        },
        {
          name: 'ctr',
          label: 'CTR %',
          align: 'left',
          field: 'ctr',
          sortable: false
        },
        {
          name: 'cadastros_pendentes',
          label: 'Cadastros Pendentes',
          align: 'left',
          field: 'cadastros_pendentes',
          sortable: false
        },
        {
          name: 'cadastros_aprovados',
          label: 'Cadastros Aprovados',
          align: 'left',
          field: 'cadastros_aprovados',
          sortable: false
        },
        {
          name: 'lcr',
          label: 'LCR %',
          align: 'left',
          field: 'lcr',
          sortable: false,
          format: (val) => `${val.toFixed(2)}%`
        },
        {
          name: 'vendas_pendentes',
          label: 'Vendas Pendentes',
          align: 'left',
          field: 'vendas_pendentes',
          sortable: false
        },
        {
          name: 'vendas_aprovadas',
          label: 'Vendas Aprovadas',
          align: 'left',
          field: 'vendas_aprovadas',
          sortable: false
        },
        {
          name: 'scr',
          label: 'SCR %',
          align: 'left',
          field: 'scr',
          sortable: false,
          format: (val) => `${val.toFixed(2)}%`
        },
        {
          name: 'valor_total_pendente',
          label: 'Valor Total Pendente',
          align: 'left',
          field: 'valor_total_pendente',
          sortable: false,
          format: (val) => this.parseMoney(val)
        },
        {
          name: 'valor_total_aprovado',
          label: 'Valor Total Aprovado',
          align: 'left',
          field: 'valor_total_aprovado',
          sortable: false,
          format: (val) => this.parseMoney(val)
        },
        {
          name: 'cpc_pendente',
          label: 'Comissão Cliques Pendente',
          align: 'left',
          field: 'cpc_pendente',
          sortable: false,
          format: (val) => this.parseMoney(val)
        },
        {
          name: 'cpc_aprovado',
          label: 'Comissão Cliques Aprovado',
          align: 'left',
          field: 'cpc_aprovado',
          sortable: false,
          format: (val) => this.parseMoney(val)
        },
        {
          name: 'comissao_pendente',
          label: 'Comissão Pendente',
          align: 'left',
          field: 'comissao_pendente',
          sortable: false,
          format: (val) => this.parseMoney(val)
        },
        {
          name: 'comissao_aprovada',
          label: 'Comissão Aprovada',
          align: 'left',
          field: 'comissao_aprovada',
          sortable: false,
          format: (val) => this.parseMoney(val)
        }
      ],

      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  methods: {
    async exportClickReport() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=report&path=/api/get/clickReport/export_csv?' + this.filterReports
        );
        if (status === 200 || status === 201) {
          console.log(data);
          this.exportTableCsv(data, this.columns, 'click-reports');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getClickReportAffiliate(`page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      }
    },

    'pagination.rowsPerPage': function () {
      this.getClickReportAffiliate(`page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage}`);
      this.ActionSetReports();
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    show() {
      return typeof this.reports === 'undefined' ? false : true;
    }
  },

  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.visibleColumns = this.columns.map((column) => column.name);
  }
};
</script>
