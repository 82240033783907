<template>
  <q-layout>
    <q-toolbar class="q-pr-none">
      <q-toolbar-title class="text-h5">{{ $t('click_report') }}</q-toolbar-title>
      <q-breadcrumbs>
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('click_report')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <q-page padding>
      <AffiliateHeaderClickReports />
      <AffiliateListClickReports />
    </q-page>
  </q-layout>
</template>

<script>
import AffiliateHeaderClickReports from '../../components/reports/affiliate/AffiliateHeaderClickReports';
import AffiliateListClickReports from '../../components/reports/affiliate/AffiliateListClickReports';

export default {
  components: { AffiliateHeaderClickReports, AffiliateListClickReports },
  name: 'AffiliateClickReports'
};
</script>
